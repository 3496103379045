import Head from 'next/head'
import NavBar from "./NavBar";
import Footer from "./Footer";


export default function Layout({// @ts-ignore
                                   children,
                                   title = 'This is the default title',
                               }) {
    return (
        <div className='flex flex-col h-screen bg-gray-50'>
            <Head>
                <title>{title}</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            </Head>
            <header className='top-0 z-50' >
                <NavBar/>
            </header>

            <main className='flex-1'>

                {children}

            </main>
            <footer className='text-center'>
                <Footer />
            </footer>
        </div>
    )
}